import { AnyAction } from "redux";
import { AdvertisementModel } from "../../shared/model/Advertisement";
import { PaginationModel } from "../../shared/model/Pagination";

export interface AdvertisementState {
  advertisementsList: AdvertisementModel[];
  advertisementPagination: PaginationModel;
  selectedAdvertisement: AdvertisementModel;
}

const INITIAL_STATE = {
  advertisementsList: [],
  advertisementPagination: {},
  selectedAdvertisement: {},
};

export const advertisementReducer = (
  state: AdvertisementState = INITIAL_STATE,
  action: AnyAction
): AdvertisementState => {
  
  const { type, payload } = action;
  switch (type) {
    case "GET_ADVERTISEMENT_LIST": {
      return {
        ...state,
        advertisementsList: payload.advertisementData,
        advertisementPagination: payload.paginationData,
      };
    }

    case "SELECTED_ADVERTISEMENT": {
      return {
        ...state,
        selectedAdvertisement: payload.selectedAdvertisement,
      };
    }
    case "ADD_ADVERTISEMENT": {
      return {
        ...state,
        advertisementsList: [
          ...state.advertisementsList,
          payload.advertisementData,
        ],
      };
    }
    case "DELETE_ADVERTISEMENT": {
      const index = state.advertisementsList.findIndex(
        (item) => item.id === payload.advertisementId
      );

      return {
        ...state,
        advertisementsList: [
          ...state.advertisementsList.splice(0, index),
          ...state.advertisementsList.splice(1),
        ],
      };
    }
    case "RESET_ADVERTISEMENT_STATE": {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default advertisementReducer;
