import { AnyAction } from "redux";
import { DistanceChargeModel } from "../../admin/models/DistanceCharge";

export interface DistanceChargeState {
  distanceChargesList: DistanceChargeModel[];
}

const INITIAL_STATE = {
  distanceChargesList: [],
};

export const distanceChargeReducer = (
  state: DistanceChargeState = INITIAL_STATE,
  action: AnyAction
): DistanceChargeState => {
  const { type, payload } = action;
  switch (type) {
    case "GET_DISTANCE_CHARGE_LIST": {
      return { ...state, distanceChargesList: payload.distanceChargesList };
    }
    default:
      return state;
  }
};

export default distanceChargeReducer;
