import * as React from "react";
// redux
import { persistor, store } from "./redux/store";
import { PersistGate } from "redux-persist/integration/react";
import { Provider } from "react-redux";
// styles and toast
import { ToastContainer } from "react-toastify";
import { TOAST_HANG_TIME } from "./config";
import "video-react/dist/video-react.css"
import "react-toastify/dist/ReactToastify.css";
import "./styles/sass/main.scss";
import "primereact/resources/primereact.min.css";   
// routes
import MainRoutes from "./Routes";
import Spinner from "./shared/components/Spinner/Spinner";
import { Loader } from '@googlemaps/js-api-loader';
import { GOOGLE_API_KEY } from "./config";
import Footer from "./shared/components/Footer/Footer";

const loader = new Loader({
  apiKey: GOOGLE_API_KEY,
  version: "weekly",
  libraries: ["places"]
});

const mapOptions = {
  center: {
    lat: 0,
    lng: 0
  },
  zoom: 4
};

// Promise
loader
  .load()
  .then((googleLoad) => {
    window.google = googleLoad
  })
  .catch(e => {
    // do something
  });

interface AppProps { }

const App: React.FunctionComponent<AppProps> = () => {
  return (
    <div>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <React.Suspense
            fallback={
              <>
                <Spinner />
              </>
            }
          >
            <MainRoutes />
            {/* <Footer/> */}
            <ToastContainer
              position="bottom-right"
              autoClose={TOAST_HANG_TIME}
              hideProgressBar
              newestOnTop
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
            />
          </React.Suspense>
        </PersistGate>
      </Provider>
    </div>
  );
};

export default App;
