import { InventoryItemModel } from "../../shared/model/InventoryItem";

export class MissingInventory {
    invType: string;
    description: string;
    label:string;
    constructor(invType: string, description: string) {
      this.invType = invType;
      this.description = description;
    }
  }
  
export class MissingInventoryList {
    list: MissingInventory[];
    constructor(list: MissingInventoryList = null) {
      this.list = [];
      if(list) {
        list.list.forEach(item => this.list.push(item));
      }
    }
    static isMissing(list: MissingInventoryList, invType: string) {
      return list.list.find((item) => item.invType == invType) != null; 
    }
    static getDescription(list: MissingInventoryList, invType: string) {
      let item = list.list.find((item) => item.invType == invType);
      return item ? item.description : "";
    }
    static addMissingInventory(list: MissingInventoryList, invType: string, description: string) {
      MissingInventoryList.removeMissingInventory(list, invType);
      list.list.push(new MissingInventory(invType, description));    
      return list;
    }
    static removeMissingInventory(list: MissingInventoryList, invType: string) {
      let index = list.list.findIndex((item) => item.invType == invType);
      if(index >= 0) {
        list.list.splice(index,1);
      }
      return list;
    }
    static updateMissingInventory(list: MissingInventoryList, invType: string, isMissing: boolean, description: string = null) {
      if(!isMissing) {
        MissingInventoryList.removeMissingInventory(list, invType);
      } else {
        MissingInventoryList.addMissingInventory(list, invType, description);
      }
      return list.clone();
    }
    clone() {
      return new MissingInventoryList(this);
    }
    static getInventoryInstructions(list: MissingInventoryList) {
      let result = [];
      list.list.forEach(missingInventory => {
        result.push({invType: missingInventory.invType, description: missingInventory.description ?? "No description"})
      });
      return result;
    }
    static getInventoryItems(list: MissingInventoryList, customerId: number = 0) {
      let result : InventoryItemModel[] = [];
      list.list.forEach(missingInventory => {
        result.push({
          id: 0,
          type: missingInventory.invType,
          quantity: 1,
          in_stock: 0,
          width: 0,
          description: missingInventory.description,
          inventory_item_has_photo: "",
          customer_id: customerId,
          label:missingInventory.label ?missingInventory.label :'',
        });
      });
      return result;
    }
}
  