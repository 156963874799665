import { AnyAction } from "redux";
import { CartItemModel } from "../../client/model/CartItem";
import { OrderSnapshotModel } from "../../client/model/OrderSnapshot";
import { PlanRequestModel } from "../../client/model/PlanRequest";
import { PropertyDetailModel } from "../../client/model/PropertyDetail";

// import model for Order
export interface OrderState {
  orderList: any[];
  cart: CartItemModel[];
  propertyDetail: PropertyDetailModel;
  cartCount: number;
  orderSummary: OrderSnapshotModel;
  distanceCharge: number;
  applyDistanceCharge: number;
  directDiscount: number;
  planTotal: number;
  planSave: number;
  subTotal: number;
  planRequestData: PlanRequestModel;
  time: number;
  invItemOrder: any[];
  packageWithPost: boolean;
  fullyBookedDates: any[];
  CustomerDetailList: {
    property: any[];
    installation: any[];
    removal: any[];
    videos: any[];
    listing: any;
    workflow: any[];
    productList: any[];
    branded: any[];
    reshoot: any[];
    virtual_staging: any[];
    virtual_twilight: any[];
    floor_plan: any[];
  };
  AppointmentDetailList: {
    appointmentsData: any[];
    groupInstruction: any[];
    marketingMaterial: any[];
  }
}
const INITIAL_STATE = {
  orderList: [],
  invItemOrder: [],
  distanceCharge: 0,
  cart: [],
  propertyDetail: {
    order_only_contact: false,
    order_only_primary: false,
  },
  planTotal: 0,
  planSave: 0,
  cartCount: 0,
  subTotal: 0,
  applyDistanceCharge: 0,
  directDiscount: 0,
  time: 0,

  planRequestData: {},
  orderSummary: {
    grossTotal: 0,
    estimatedTotal: 0,
    additionalAddonTotal: 0,
    inventoryAddonTotal: 0,
    planTotal: 0,
    distanceCharge: 0,
    subTotal: 0,
    discount: 0,
  },
  packageWithPost: false,
  fullyBookedDates: [],
  CustomerDetailList: {
    property: [],
    installation: [],
    removal: [],
    videos: [],
    listing: {},
    workflow: [],
    productList: [],
    branded: [],
    reshoot: [],
    virtual_staging: [],
    virtual_twilight: [],
    floor_plan: [],
  },
  AppointmentDetailList:{
    appointmentsData: [],
    groupInstruction: [],
    marketingMaterial: []
  }
};

export const orderReducer = (
  state: OrderState = INITIAL_STATE,
  action: AnyAction
): OrderState => {
  const { type, payload } = action;
  switch (type) {
    case "GET_ORDER_LIST": {
      return {
        ...state,
        orderList: payload.orderList,
      };
    }
    case "ADD_TIME_TO_ORDER": {
      return {
        ...state,
        time: state.time + payload.time,
      };
    }

    case "REMOVE_TIME_FROM_ORDER": {
      return {
        ...state,
        time: state.time - payload.time,
      };
    }

    case "FLUSH_CART": {
      return {
        ...state,
        cart: [],
      };
    }
    case "GET_ORDER_LIST_BY_CUSTOMER_ID": {
      return {
        ...state,
        orderList: payload.orderList,
      };
    }

    case "ADD_DIRECT_DISCOUNT": {
      return {
        ...state,
        directDiscount: payload.discount,
      };
    }
    case "ADD_PROPERTY_DETAIL": {
      return {
        ...state,
        propertyDetail: {
          ...state.propertyDetail, // Preserve existing data
          ...payload.propertyDetail, // Merge with new data
        },
      };
    }

    case "GET_INV_ORDER": {
      return {
        ...state,
        invItemOrder: state.invItemOrder, // Return the invItemOrder
      };
    }
    case "ADD_TO_INV_ORDER"
      : {
        const { code } = action.payload;
        if (state.invItemOrder.includes(code)) {
          return state;
        }

        const updatedInvItemOrder = [...state.invItemOrder, code];
        return {
          ...state,
          invItemOrder: updatedInvItemOrder,
        };
      }

    case "REMOVE_FROM_INV_ORDER": {
      const { code } = action.payload;

      // Remove the code from invItemOrder
      const updatedInvItemOrder = state.invItemOrder.filter(
        (item) => item !== code
      );

      return {
        ...state,
        invItemOrder: updatedInvItemOrder,
      };
    }

    case "GET_ORDER_SUMMARY_SNAPSHOT": {
      let _addOnCart = state.cart;
      let _additionalCost = 0;
      let _additionalSubTotal = 0;
      let _discount = 0;
      let _time = 0;
      let _grossTotal = 0;
      let _directDiscount = state.directDiscount;
      let _planSave = state.planSave | 0;
      let _planTotal = state.planTotal | 0;

      for (let i = 0; i < _addOnCart.length; i++) {
        _additionalSubTotal += _addOnCart[i].price * _addOnCart[i].quantity;
        if (_addOnCart[i].meta.additionalCost) {
          _additionalCost +=
            _addOnCart[i].meta.additionalCost * _addOnCart[i].quantity;
        }
        if (_addOnCart[i].meta.tracks) {
          _addOnCart[i].meta.tracks.forEach((track) => {
            if (track.voice_over) {
              _additionalCost += 50;
            }
          });
        }
        _grossTotal +=
          _addOnCart[i].type === "included"
            ? _addOnCart[i].price * (_addOnCart[i].quantity - 1)
            : _addOnCart[i].initialPrice * _addOnCart[i].quantity;
        _discount += _addOnCart[i].discount * _addOnCart[i].quantity;
        _time += _addOnCart[i].time * _addOnCart[i].quantity;
      }
      _discount += _directDiscount;
      _discount += _planSave;
      let _inventoryAddonTotal = 0;

      let _applyDC = state.applyDistanceCharge;
      let _distantCharge = _applyDC > 0 ? state.distanceCharge : 0;

      _grossTotal += _planTotal + _additionalCost;
      let _netTotal = 0;
      _netTotal = _grossTotal + _distantCharge - _discount;

      let _orderSummarySnapshot = {
        additionalAddonTotal: _additionalSubTotal,
        inventoryAddonTotal: _inventoryAddonTotal,
        planTotal: _planTotal,
        distanceCharge: _distantCharge,
        discount: _discount,
        grossTotal: _grossTotal,
        netTotal: _netTotal,
        time: _time,
        package_has_post: state.packageWithPost
      };
      return {
        ...state,
        orderSummary: _orderSummarySnapshot,
      };
    }
    case "ADD_TO_ORDER": {
      let _cart = {
        id: action.payload.id,
        parent_product_id: action.payload.parent_product_id,
        quantity: action.payload.quantity,
        name: action.payload.name,
        price: action.payload.price,
        initialPrice: action.payload.initialPrice,
        discount: action.payload.discount,
        type: action.payload.type,
        incremental: action.payload.incremental,
        time: action.payload.time,
        code: action.payload.code,
        distance_charge: action.payload.distance_charge,
        meta: action.payload.meta,
        sub_products: action.payload.sub_products,
        product_variant_id: action.payload.product_variant_id,
      };

      return {
        ...state,
        cart: [...state.cart, _cart],
        cartCount: state.cartCount + _cart.quantity,
        subTotal: state.subTotal + _cart.quantity * _cart.price,

      };
    }

    case "ADD_TO_META": {
      const { code, meta } = payload;
      const updatedCart = state.cart.map((item: CartItemModel) => {
        if (item.code === code) {
          return {
            ...item,
            meta: { ...item.meta, ...meta },
          };
        }
        return item;
      });
      return { ...state, cart: updatedCart };
    }

    case "REMOVE_FROM_ORDER": {
      const index = state.cart.findIndex((item) => item.code === payload.code);

      if (index !== -1 && state.cart[index].quantity > 0) { // Check if item exists and quantity is valid
        let quantity_ = state.cart[index].quantity;
        let time_ = state.cart[index].time;

        return {
          ...state,
          cart: [...state.cart.slice(0, index), ...state.cart.slice(index + 1)],
          cartCount: state.cartCount - quantity_,
          time: state.time - time_,
        };
      } else {
        // Handle the case where the item is not found or quantity is 0 
        return state; // You could optionally return an error state or display a message 
      }
    }


    case "SET_DISTANCE_CHARGE": {
      return {
        ...state,
        distanceCharge: payload.distanceCharge,
      };
    }
    case "CLEAR_ORDER": {
      return {
        ...state,
        orderList: [],
        invItemOrder: [],
        distanceCharge: 0,
        cart: [],
        planTotal: 0,
        planSave: 0,
        cartCount: 0,
        subTotal: 0,
        applyDistanceCharge: 0,
        directDiscount: 0,
        time: 0,
        orderSummary: {},
        planRequestData: {},
        propertyDetail: {},
        packageWithPost: false
      };
    }

    case "SET_PLAN_TOTAL": {
      return {
        ...state,
        planTotal: payload.planTotal,
      };
    }
    case "UPDATE_PLAN_TOTAL": {
      const updatedPlanTotal = state.planTotal + payload.amount;

      return {
        ...state,
        planTotal: updatedPlanTotal,
      };
    }

    case "SET_PLAN_DISCOUNT": {
      return {
        ...state,
        planSave: payload.planDiscount,
      };
    }

    case "INCREASE_ORDER_QUANTITY": {
      state.cartCount++;
      const index = state.cart.findIndex((item) => item.id === payload);
      let time_ = state.cart[index].time;

      state.cart[index].quantity++;
      return {
        ...state,
        time: state.time + time_,
      };
    }

    case "DECREASE_ORDER_QUANTITY": {
      const index = state.cart.findIndex((item) => item.id === payload);
      let quantity = state.cart[index].quantity;
      let time_ = state.cart[index].time;

      if (quantity > 1) {
        state.cartCount--;
        state.cart[index].quantity--;
      }
      return {
        ...state,
        time: state.time - time_,
      };
    }

    case "INCREASE_DISTANCE_CHARGE": {
      return {
        ...state,
        applyDistanceCharge: state.applyDistanceCharge + 1,
      };
    }

    case "DECREASE_DISTANCE_CHARGE": {
      return {
        ...state,
        applyDistanceCharge: state.applyDistanceCharge - 1,
      };
    }

    case "SET_PLAN_TIME": {
      return {
        ...state,
        time: payload.planTime,
      };
    }

    case "ORDER_NUMBER": {
      return {
        ...state,
      };
    }

    case "ADD_PLAN_REQUEST_DATA": {
      return {
        ...state,
        planRequestData: payload.planRequestData,
      };
    }
    case "RESET_ORDER_STATE": {
      return INITIAL_STATE;
    }

    case "SET_PACKAGE_WITH_POST": {
      return { ...state, packageWithPost: payload.value };
    }

    case "SET_FULLY_BOOKED_DATES": {
      return { ...state, fullyBookedDates: payload.value };
    }

    case "SET_CUSTOMER_DETAIL": {
      return {
        ...state,
        CustomerDetailList: {
          ...state.CustomerDetailList,
          installation: payload.value.installation,
          property: payload.value.property,
          removal: payload.value.removal,
          videos: payload.value.videos,
          listing: payload.value.updatedListing,
          workflow: payload.value.workflow,
          productList: payload.value.productList,
          branded: payload.value.branded,
          reshoot: payload.value.reshoot,
          virtual_staging: payload.value.virtual_staging,
          virtual_twilight: payload.value.virtual_twilight,
          floor_plan: payload.value.floor_plan
        }
      };
    }

    case "SET_APPOINTMENT_DETAIL": {
      return {
        ...state,
        AppointmentDetailList: {
          ...state.AppointmentDetailList,
          appointmentsData: payload.value.appointmentsData,
          groupInstruction: payload.value.groupInstruction,
          marketingMaterial: payload.value.marketingMaterial
        }
      };
    }

    default:
      return state;
  }
};

export default orderReducer;
