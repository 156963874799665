import { combineReducers } from "redux";
import advertisementReducer from "./advertisemnet/advertisement.reducer";
import authReducer from "./auth/auth.reducer";
import marketReducer from "./market/market.reducer";
import orderReducer from "./order/order.reducer";
import pickupReducer from "./PickupAddress/pickup.reducers";
import userReducer from "./user/user.reducer";
import adminReducer from "./admin/admin.reducer";
import planReducer from "./plan/plan.reducer";
import distanceChargeReducer from "./distanceCharge/distanceCharge.reducer";
import paymentReducer from "./payment/payment.reducer";
import statusReducer from "./status/status.reducer";
import announcementReducer from "./announcement/announcement.reducer";
import instReducer from "./inst/inst.reducer";
import ProductReducer from "./product/product.reducer";
import discountReducer  from "./discount/discount.reducer";
import myInventoryReducer from "./myInventory/myInventory.reducers"
import { loadingReducer } from "./loader/loader.reducers";


const rootReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
  market: marketReducer,
  advertisement: advertisementReducer,
  order: orderReducer,
  pickup: pickupReducer,
  admin: adminReducer,
  discount:discountReducer,
  plan: planReducer,
  product: ProductReducer,
  distanceCharge: distanceChargeReducer,
  payment: paymentReducer,
  announcement: announcementReducer,
  status:statusReducer,
  inst:instReducer,
  inventory:myInventoryReducer,
  loading:loadingReducer,
});

export default (state, action) =>
  rootReducer(action.type === "LOGOUT" ? undefined : state, action);
// export default rootReducer;
