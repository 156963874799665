import { AnyAction } from "redux";
import { AnnouncementModel } from "../../shared/model/Announcement";
import { PaginationModel } from "../../shared/model/Pagination";

export interface AnnouncementState {
  announcementsList: AnnouncementModel[];
  lastCreatedAnnouncement: AnnouncementModel[];
  announcementPagination: PaginationModel;
  selectedAnnouncement: AnnouncementModel;
}

const INITIAL_STATE = {
  announcementsList: [],
  announcementPagination: {},
  lastCreatedAnnouncement:[],
  selectedAnnouncement: {},
};

export const announcementReducer = (
  state: AnnouncementState = INITIAL_STATE,
  action: AnyAction
): AnnouncementState => {
  const { type, payload } = action;
  switch (type) {
    case "GET_ANNOUNCEMENT_LIST": {
      return {
        ...state,
        announcementsList: payload.announcementData,
        lastCreatedAnnouncement:payload.lastCreatedAnnouncement,
        announcementPagination: payload.paginationData,
      };
    }

    case "SELECTED_ANNOUNCEMENT": {
      return {
        ...state,
        selectedAnnouncement: payload.selectedAnnouncement,
      };
    }
    case "ADD_ANNOUNCEMENT": {
      return {
        ...state,
        announcementsList: [
          ...state.announcementsList,
          payload.announcementData,
        ],
      };
    }
    case "DELETE_ANNOUNCEMENT": {
      const index = state.announcementsList.findIndex((item) => item.id === payload.announcementId);
    
      return {
        ...state,
        announcementsList: [...state.announcementsList.splice(0, index), ...state.announcementsList.splice(1)],
 
      };
    }

    default:
      return state;
  }
};

export default announcementReducer;
