import { AnyAction } from "redux";
import { TitleCompanyModel } from "../../admin/models/TitleCompany";
import { BrokerageModel } from "../../shared/model/Brokerage";
import { PaginationModel } from "../../shared/model/Pagination";
import { UserProfileModel } from "../../shared/model/UserProfile";

export interface UserState {
  brokerageList: UserProfileModel[];
  brokeragePagination: PaginationModel;
  userList: UserProfileModel[];
  userPagination: PaginationModel;
  titleCompanyList: TitleCompanyModel[];
  titleCompanyPagination: PaginationModel
  linkedAccountList: any[];
  otherContactList: any[];
  linkedSubAccountList: any[];
  userAddress: any;
  user: any;
  broker: BrokerageModel;
  userBrokerage: BrokerageModel;
  titleCompany: TitleCompanyModel;
  successStatus: boolean;
  webLocation: string;
}

const INITIAL_STATE = {
  brokerageList: [],
  brokeragePagination: {},
  userList: [],
  userPagination: {},
  titleCompanyList: [],
  titleCompanyPagination: {},
  linkedAccountList: [],
  otherContactList: [],
  linkedSubAccountList: [],
  userAddress: null,
  user: null,
  broker: null,
  titleCompany: null,
  userBrokerage: null,
  successStatus: false,
  webLocation: null
};
export const userReducer = (
  state: UserState = INITIAL_STATE,
  action: AnyAction
): UserState => {
  const { type, payload } = action;
  switch (type) {
    case "GET_BROKERAGES_LIST": {
      return {
        ...state, brokerageList: payload.brokerageList,
        brokeragePagination: payload.paginationData
      };
    }
    case "ADD_BROKERAGE": {
      return { ...state, userBrokerage: payload.brokerage };
    }
    case "GET_USERS_LIST": {
      return {
        ...state, userList: payload.userList,
        userPagination: payload.paginationData
      };
    }
    case "GET_CUSTOMER_LINKED_ACCOUNT_LIST": {
      return { ...state, linkedAccountList: payload.linkedAccountList };
    }
    case "GET_LINKED_ACCOUNTS_LIST": {
      return { ...state, linkedAccountList: payload.linkedAccountList };
    }
    case "GET_OTHER_CONTACT_LIST": {
      return { ...state, otherContactList: payload.otherContactList };
    }
    case "ADD_OTHER_CONTACT": {
      return { ...state, otherContactList: [...state.otherContactList, payload.otherContact] };
    }
    case "EDIT_OTHER_CONTACT_LIST": {
      return { ...state, otherContactList: [...state.otherContactList, payload.otherContact] };
    }
    case "GET_LINKED_SUB_ACCOUNT_LIST": {
      return { ...state, linkedSubAccountList: payload.linkedSubAccountList };
    }
    case "GET_ADMIN_CREATE_USER_ADDRESS": {
      return {
        ...state,
        userAddress: {
          ...state.userAddress,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          zip_code: payload.postalCode,
        },
      };
    }
    case "CLEAR_ADMIN_CREATE_USER_ADDRESS": {
      return {
        ...state,
        userAddress: {
          ...state.userAddress,
          address: "",
          city: "",
          state: "",
          zip_code: "",
        },
      };
    }
    case "UPDATE_SUCCESS": {
      return {
        ...state,
        user: payload.user,
      };
    }

    case "GET_USER_BY_ID": {
      return { ...state, user: payload.user };
    }
    case "GET_USER_BY_TOKEN": {
      return { ...state, user: payload.user };
    }
    case "GET_SELECTED_BROKERAGE": {
      return { ...state, broker: payload.selectedBrokerage };
    }
    case "FETCH_TITLE_COMPANIES_LIST": {
      return {
        ...state, titleCompanyList: payload.titleCompaniesList,
        titleCompanyPagination: payload.paginationData
      };
    }
    case "GET_SELECTED_TITLE_COMPANY": {
      return {
        ...state, titleCompany: payload.selectedTitleCompany,
      };
    }
    case "GET_UPDATE_PROFILE_ADDRESS": {
      return {
        ...state,
        user: {
          ...state.user,
          address: payload.address,
          city: payload.city,
          state: payload.state,
          zip_code: payload.postalCode,
        },
      };
    }
    case "CLEAR_UPDATE_PROFILE_ADDRESS": {
      return {
        ...state,
        user: {
          ...state.user,
          address: "",
          city: "",
          state: "",
          zip_code: "",
        },
      };
    }
    case "REQUEST_SUCCESS": {
      return {
        ...state,
        successStatus: true,
      };
    }
    case "REQUEST_FAIL": {
      return {
        ...state,
        successStatus: false,
      };
    }

    case "TOGGLE_REQUEST_STATUS": {
      return {
        ...state,
        successStatus: !state.successStatus,
      };
    }
    case "TOGGLE_WEB_LOCATION": {
      return {
        ...state,
        webLocation: payload.location ,
      };
    }
    case "RESET_USER_STATE": {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default userReducer;
