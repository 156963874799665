import { AnyAction } from "redux";

import { PaginationModel } from "../../shared/model/Pagination";
import { DiscountCodeModel } from "../../admin/models/DiscountCode";

export interface DiscountState {
  discountList: DiscountCodeModel[];
  discountPagination: PaginationModel;
  selectedDiscount: DiscountCodeModel;
}

const INITIAL_STATE = {
  discountList: [],
  discountPagination: {},
  selectedDiscount: {},
};

export const discountReducer = (
  state: DiscountState = INITIAL_STATE,
  action: AnyAction
): DiscountState => {
  
  const { type, payload } = action;
  switch (type) {

    case "GET_DISCOUNT_LIST": {
      return {
        ...state,
        discountList: payload.discountData,
        discountPagination: payload.paginationData,
      };
    }

    case "SELECTED_DISCOUNT": {
      return {
        ...state,
        selectedDiscount: payload.selectedDiscount,
      };
    }
    case "ADD_DISCOUNT": {
      return {
        ...state,
        discountList: [
          ...state.discountList,
          payload.discountData,
        ],
      };
    }
    case "DELETE_DISCOUNT": {
      const index = state.discountList.findIndex(
        (item) => item.id === payload.discountCodeId
      );

      return {
        ...state,
        discountList: [
          ...state.discountList.splice(0, index),
          ...state.discountList.splice(1),
        ],
      };
    }
    case "RESET_DISCOUNT_STATE": {
      return INITIAL_STATE;
    }

    default:
      return state;
  }
};

export default discountReducer;
