import { AnyAction } from "redux";
import { AddOnModel } from "../../shared/model/AddOn";
import { PaginationModel } from "../../shared/model/Pagination";
import { SqftRangeModel } from "../../shared/model/SqftRange";
import { ProductTypeModel } from "../../shared/model/ProductType";

export interface ProductState {
  productsList: AddOnModel[];
  productPagination: PaginationModel;
  selectedProduct: AddOnModel;
  productVariantsList: AddOnModel[];
  productVariantPagination: PaginationModel;
  selectedProductVariant: AddOnModel;
  sqftList: SqftRangeModel[];
  productTypes: ProductTypeModel[];
  featuredProducts: AddOnModel[];
}

const INITIAL_STATE = {
  productsList: [],
  productPagination: {},
  selectedProduct: {},
  productVariantsList: [],
  productVariantPagination: {},
  selectedProductVariant: {},
  sqftList: [],
  productTypes: [],
  featuredProducts: [],
};

export const ProductReducer = (
  state: ProductState = INITIAL_STATE,
  action: AnyAction
): ProductState => {
  
  const { type, payload } = action;
  switch (type) {
    case "GET_PRODUCT_LIST": {
      return {
        ...state,
        productsList: payload.productData,
        productPagination: payload.paginationData,
      };
    }

    case "SELECTED_PRODUCT": {
      return {
        ...state,
        selectedProduct: payload.selectedProduct,
      };
    }
    case "ADD_PRODUCT": {
      return {
        ...state,
        productsList: [
          ...state.productsList,
          payload.ProductData,
        ],
      };
    }
    case "DELETE_PRODUCT": {
      const index = state.productsList.findIndex(
        (item) => item.id === payload.ProductId
      );

      return {
        ...state,
        productsList: [
          ...state.productsList.splice(0, index),
          ...state.productsList.splice(1),
        ],
      };
    }
    case "FETCH_FEATURED_PRODUCTS": {
        return {
          ...state,
          featuredProducts: payload.featuredServices,
        };
      }
    case "UPLOAD_FEATURED_VIDEO_ARRAY": {
        return {
          ...state,
          selectedProduct: {
            ...state.selectedProduct,
            product_videos: payload.videos,
          },
        };
      }

      case "DELETE_FEATURED_VIDEO": {
        const index = state.selectedProduct.product_videos.findIndex(
          (item) => item.id === payload.track_id
        );
        return {
          ...state,
          selectedProduct: {
            ...state.selectedProduct,
            product_videos: [
              ...state.selectedProduct.product_videos.splice(0, index),
              ...state.selectedProduct.product_videos.splice(1),
            ],
          },
        };
      }
  
      case "DELETE_FEATURED_IMAGE": {
        const index = state.selectedProduct.product_photos.findIndex(
          (item) => item.id === payload.track_id
        );
        return {
          ...state,
          selectedProduct: {
            ...state.selectedProduct,
            product_photos: [
              ...state.selectedProduct.product_photos.splice(0, index),
              ...state.selectedProduct.product_photos.splice(1),
            ],
          },
        };
      }

      case "FETCH_PRODUCT_TYPES": {
        return {
          ...state,
          productTypes: payload.productTypes,
        };
      }

    case "RESET_PRODUCT_STATE": {
      return INITIAL_STATE;
    }

    case "GET_PRODUCT_VARIANT_LIST": {
        return {
          ...state,
          productVariantsList: payload.productVariantData,
          productVariantPagination: payload.paginationData,
        };
      }
  
      case "SELECTED_PRODUCT_VARIANT": {
        return {
          ...state,
          selectedProductVariant: payload.selectedProductVariant,
        };
      }
      case "ADD_PRODUCT_VARIANT": {
        return {
          ...state,
          productVariantsList: [
            ...state.productVariantsList,
            payload.productVariantData,
          ],
        };
      }
      case "DELETE_PRODUCT_VARIANT": {
        const index = state.productVariantsList.findIndex(
          (item) => item.id === payload.productVariantId
        );
  
        return {
          ...state,
          productVariantsList: [
            ...state.productVariantsList.splice(0, index),
            ...state.productVariantsList.splice(1),
          ],
        };
      }
      case "RESET_PRODUCT_VARIANT_STATE": {
        return {
          ...state,
          selectedProductVariant: {},
        };
      }

      case "FETCH_SQFT_RANGES": {
        return {
          ...state,
          sqftList: payload.sqftList,
        };
      }
    

    default:
      return state;
  }
};

export default ProductReducer;
