import { AnyAction } from "redux";
import { UserProfileModel } from "../../shared/model/UserProfile";
import { TokenModel } from "../../shared/model/Token";

const user = JSON.parse(localStorage.getItem("user") as any);
let currentUser: any, token: any;
if (user) {
  currentUser = user.currentUser;
  token = user.token;
} else {
  currentUser = null;
  token = null;
}
export interface AuthState {
  currentUser: UserProfileModel;
  isLoggedIn: boolean;
  token: TokenModel;
  resetPasswordEmail: string | null;
  emailVerifySuccess: boolean;
  emailValid: boolean;
  userId: number | null;
  role: string | null;
  brokerage: {
    brokerage_address?: string;
    brokerage_city?: string;
    brokerage_state?: string;
  };

}

const INITIAL_STATE = {
  currentUser: currentUser,
  token: token,
  isLoggedIn: false,
  resetPasswordEmail: "",
  emailVerifySuccess: false,
  emailValid: false,
  userId: null,
  role: null,
  brokerage: {
    brokerage_address: "",
    brokerage_city: "",
    brokerage_state: "",
  },

};

export const authReducer = (
  state: AuthState = INITIAL_STATE,
  action: AnyAction
): AuthState => {
  const { type, payload } = action;

  switch (type) {
    case "LOGIN_SUCCESS": {
      return {
        ...state,
        isLoggedIn: true,
        currentUser: payload.currentUser,
        token: payload.token,
      };
    }
    case "GET_USER_ROLE": {
      return {
        ...state,
        role: payload.role,
      };
    }
    case "LOGIN_FAILED": {
      return {
        ...state,
        isLoggedIn: false,
        currentUser: null,
        token: null,
      };
    }

    // case "LOGOUT": {
    //   return {
    //     ...state,
    //     currentUser: null,
    //     token: null,
    //     isLoggedIn: false,
    //     role: null,
    //     // user: null,
    //   };
    // }

    case "REFRESH_TOKEN": {
      return {
        ...state,
        token: { ...state.token, accessToken: payload },
      };
    }

    case "REGISTER_SUCCESS": {
      return {
        ...state,
        isLoggedIn: false,
      };
    }

    case "GET_CURRENT_USER": {
      return state;
    }


    case "RESET_PASSWORD_REQUEST": {
      return {
        ...state,
        resetPasswordEmail: payload.email,
      };
    }

    case "CLEAR_RESET_PASSWORD": {
      return {
        ...state,
        resetPasswordEmail: null,
      };
    }
    case "EMAIL_VERIFY_SUCCESS": {
      return {
        ...state,
        emailVerifySuccess: true,
        userId: payload.userId,
      };
    }
    case "CHECK_EMAIL": {
      return {
        ...state,
        emailValid: payload.emailValid,
      };
    }
    case "UPLOAD_BROKERAGE_ICON": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          brokerage_icon: payload.imageURL,
        },
      };
    }
    case "UPLOAD_TITLE_COMPANY_ICON": {
      return {
        ...state,
        currentUser: {
          ...state.currentUser,
          title_company_icon: payload.imageURL,
        },
      };
    }

    default:
      return state;
  }
};

export default authReducer;
