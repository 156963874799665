import { AnyAction } from "redux";
import { AddOnModel } from "../../shared/model/AddOn";
import { PackageModel } from "../../shared/model/Package";
import { PackageCodes, ProductCodes } from "../../shared/enums/product-code";

export interface PlanState {
  plans: PackageModel[];
  selectedPlan: PackageModel;
  selectedPlanProductVariantList: AddOnModel[];
  planProductList: AddOnModel[];
  planVariantProductList: AddOnModel[];
  packageCreated: boolean;
  packageUpdated: boolean;

}

const INITIAL_STATE = {
  plans: [],
  selectedPlan: {},
  selectedPlanProductVariantList: [],
  planVariantProductList: [],
  planProductList: [],
  packageCreated: false,
  packageUpdated: false,
};

export const planReducer = (
  state: PlanState = INITIAL_STATE,
  action: AnyAction
): PlanState => {
  const { type, payload } = action;
  switch (type) {
    
    case "FETCH_PLAN_PRODUCTS_VARIANT": {
      return {
        ...state,
        planVariantProductList: payload.planProductList,
      };
    }

    case "FETCH_PLANS": {
      return {
        ...state,
        plans: payload.plans,
      };
    }

     case "FETCH_PLAN_PRODUCT_VARIANT_LIST": {
      const { selectedPlanProductVariantList } = payload;

      // Update the existing list by adding the new attribute 'new_price' to all items
      const updatedList = state.selectedPlanProductVariantList.map(existingProduct => ({
        ...existingProduct,
        new_price: existingProduct.price, // Set the default value as needed
      }));

      // Update the identified product variants with the fields from selectedPlanProductVariantList
      const newList = updatedList.map(existingProduct => {
        const matchingProduct = selectedPlanProductVariantList.find(newProduct => newProduct.package_variant_id === existingProduct.package_variant_id);
        return matchingProduct ? matchingProduct : existingProduct;
      });

      // Append any new products that don't exist in the current list
      const finalList = [...newList, ...selectedPlanProductVariantList.filter(newProduct => !newList.some(existingProduct => existingProduct.package_variant_id === newProduct.package_variant_id))];

      return {
        ...state,
        selectedPlanProductVariantList: finalList,
      };
    }

    
    case "UPDATE_PLAN_PRODUCT_VARIANT_BY_ID": {
      const { addonData } = payload;
    
      // Find the index of the product variant to be updated
      const indexToUpdate = state.selectedPlanProductVariantList.findIndex(
        (productVariant) =>
          productVariant.package_variant_id === addonData.package_variant_id
      );
    
      // If the product variant is found, update the specific field(s)
      if (indexToUpdate !== -1) {
        const updatedList = [...state.selectedPlanProductVariantList];
        
        // Update the identified product variant with the fields from addonData
        updatedList[indexToUpdate] = {
          ...updatedList[indexToUpdate],
          ...addonData,
        };
    
        return {
          ...state,
          selectedPlanProductVariantList: updatedList,
        };
      }
    
      // If the product variant is not found, return the current state
      return state;
    }
    
    
    
    case "RESET_SELECTED_PLAN_PRODUCT_VARIANT_LIST": {
      return {
        ...state,
        selectedPlanProductVariantList: [],
      };
    }
    
    
    case "SET_SELECTED_PLAN": {
      return {
        ...state,
        selectedPlan: payload.selectedPlan,
      };
    }

    case "UPDATE_SELECTED_PLAN": {

      const amount = payload.amount;
    
      const updatedSelectedPlan = { ...state.selectedPlan };
      updatedSelectedPlan.price += updatedSelectedPlan.code == PackageCodes.ALA_CARTE ? 0 : amount;

      return {
        ...state,
        selectedPlan: updatedSelectedPlan,
      };
    }
    case "ADD_PARENT_PRODUCT_TO_PLAN": {
      const { product } = action.payload;

      // Create a new state object with the updated selectedPlan
      const updatedState: PlanState = {
        ...state,
        selectedPlan: {
          ...state.selectedPlan,
          products: [...state.selectedPlan.products, product],
        },
      };

      return updatedState;
    }
    
    case "GET_PACKAGE_BY_ID": {
      return {
        ...state,
        selectedPlan: payload.package,
      };
    }

    case "PACKAGE_PRODUCT_VARIANTS_EDITED": {
      return {
        ...state,
        packageUpdated: true,
      };
    }

    case "SET_SELECTED_PLAN": {
      return {
        ...state,
        planProductList: payload.planProductList,
      };
    }

    case "PRODUCT_VARIANT_EDITED": {
      return state;
    }

    case "RESET_PLAN_STATE": {
      return  INITIAL_STATE;;
    }

    case "CLEAR_SELECTED_PLAN": {
      return {
        ...state,
        selectedPlan: {},
      };
    }

  
    case "PACKAGE_CREATE_SUCCESS": {
      return {
        ...state,
        packageCreated: true,
      };
    }
    case "PACKAGE_CREATE_FAIL": {
      return {
        ...state,
        packageCreated: false,
      };
    }

    case "PACKAGE_CREATE_TOGGLE_STATUS": {
      return {
        ...state,
        packageCreated: !state.packageCreated,
      };
    }
 

    default:
      return state;
  }
};

export default planReducer;
