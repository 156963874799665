import { AnyAction } from 'redux';

const INITIAL_STATE = {
  inventoryItemList: [],
};

export const myInventoryReducer = (
  state: any = INITIAL_STATE,
  action: AnyAction
): any => {
  const { type, payload } = action;
  switch (type) {
    case 'GET_MYINVENTORY_LIST': {
      return {
        ...state,
        inventoryItemList: payload.inventoryList,
      };
    }
    default:
      return state;
  }
};

export default myInventoryReducer;
