import { AnyAction } from "redux";
import { AdminModel } from "../../admin/models/Admin";
import { PaginationModel } from "../../shared/model/Pagination";

export interface AdminState {
  adminList: any[];
  adminPagination: PaginationModel;
  admin: AdminModel;
  successStatus: boolean;
}

const INITIAL_STATE = {
  adminList: [],
  adminPagination: {},
  admin: null,
  successStatus: false,
};

export const adminReducer = (
  state: any = INITIAL_STATE,
  action: AnyAction
): AdminState => {
  const { type, payload } = action;

  switch (type) {
    case "GET_ADMIN_LIST": {
      return {
        ...state,
        adminList: payload.adminData,
        adminPagination: payload.paginationData,
      };
    }
    case "GET_ADMIN_BY_ID": {
      return {
        ...state,
        admin: payload.adminData,
      };
    }
    case "SUCCESS": {
      return {
        ...state,
        successStatus: true,
      };
    }
    case "FAIL": {
      return {
        ...state,
        successStatus: false,
      };
    }

    case "TOGGLE_STATUS": {
      return {
        ...state,
        successStatus: !state.successStatus,
      };
    }
    default:
      return state;
  }
};

export default adminReducer;
