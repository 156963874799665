export enum PackageCodes {
  ALA_CARTE = "ala_carte"
}

export enum ProductCodes {
  TWILIGHT_PHOTOS = "new_twilight_photos",
  EXPRESS_3D_MATTERPORT = "new_matterport_express",
  UNLIMITED_PHOTOS = "new_wide_angle_photos",
  FLOOR_PLAN_2D = "new_2d_floorplan",
  COMMUNITY_PHOTOS = "new_community_solo",
  VIRTUAL_TWILIGHT = "new_virtual_twilight",
  ADVANCED_PHOTO_EDITING = "new_advanced_photo_editing",
  EXPRESS_HD_WALKTHROUGH_VIDEO = "new_walkthrough_video",
  PREMIUM_HD_WALKTHROUGH_VIDEO = "new_enhanced_walkthrough_video",
  AGENT_MIC_OVER_PREMIUM="new_agent_narration_premium",
  AGENT_MIC_OVER_EXPRESS="new_add_mic/agent_narration",
  AGENT_MIC_OVER_SOCIAL = "add_mic/agent_narration_Social",
  PREMIUM_MATTERPORT_3D_FLOOR_PLAN = "new_matterport",
  FLOOR_PLAN_W_MATTERPORT_2D = "new_matterport_floorplan",
  ZILLOW_3D_TOUR = "new_zillow_3d_tour",
  DRONE_PROPERTY_PHOTOS = "new_drone_property_photos",
  DRONE_PROPERTY_VIDEOS = "new_drone_property_video",
  DRONE_PROPERTY_PHOTOS_W_VIDEO = "new_drone_property_pv",
  DRONE_COMMUNITY_PHOTOS = "new_drone_commmunity_photos",
  DRONE_COMMUNITY_VIDEOS = "new_drone_community_video",
  DRONE_COMMUNITY_PHOTOS_W_VIDEO = "new_drone_community_pv",
  VIRTUAL_STAGING = "new_virtual_staging",
  ZILLOW_WALKTHROUGH_VIDEO = "new_zillow_walkthrough_video",
  LUXURY_PHOTOS = "new_luxury_photos",
  MARKETING_MATERIALS = "new_marketing_materials",
  WIND_CHAIN = "new_wind_chain",
  RENTAL_RIDER = "new_rental_rider",
  STANDARD_POST = "new_standard_post",
  FLYER_BOX_TUBE_RENTAL = "new_flyer_box/flyer_tube_rental",
  FLYER_TUBE_RENTAL="flyer_tube_rental",
  H_FRAME_LUX = "new_h-frame/luxury",
  OVER_THE_WALL = "new_over_the_wall",
  INSTALL_SIGN_ONLY = "new_install_sign_only",
  INSTALL_RIDER_ONLY = "new_install_rider_only",
  INSTALL_LOCKBOX_ONLY = "new_install_lockbox_only",
  FLYER = "new_branded_flyer",
  STANDARD_FLYER = "new_standard_flyer",
  LUXURY_FLYER = "new_luxury_flyer",
  CLEAN_FLYER_DESIGN = "new_clean_flyer_design",
  BANNER = "new_banner",
  MODERN_BANNER = "new_modern_banner",
  STANDARD_BANNER = "new_standard_banner",
  STANDARD_DOOR_HANGER = "new_standard_door_hanger",
  LUXURY_DOOR_HANGER = "new_luxury_door_hanger",
  CLEAN_DOOR_HANGER = "new_clean_door_hanger",
  STANDARD_LAYOUT = "new_standard_layout",
  CLASSIC_LAYOUT = "new_classic_layout",
  VIRTUAL_TOUR= "new_virtual_tour",
  SINGLE_PROPERTY_WEBSITE = "new_single_property_website",
  EXPRESS_MATTERPORT_3D_FLOOR_PLAN = "new_express_3d_matterport",
  MLS_ENTRY = "new_mls_entry",
  CO_LISTER = "new_co_lister",
  PUBLIC_REMARKS = "new_public_remarks",
  BRANDED_FLYER_STANDARD="new_branded_flyer_standard",
  BRANDED_FLYER_MODERN="new_branded_flyer_modern",
  BRANDED_PHOTOS="new_branded_photos",
  BRANDED_PHOTOS_MODERN="new_branded_photos_modern",
  BRANDED_PHOTOS_STANDARD="new_branded_photos_standard",
  PRINTED_FLYERS="new_printed_flyers",
  PRINTED_MARKETING_FLYERS="new_50_markerting_flyers_printed",
  PRINTED_FLYERS_DELIVER="new_printed_flyers_deliver",
  PRINTED_FLYERS_RL_OFFICE="new_printed_flyers_pick_up_at_rl_office",
  PREMIUM_WALKTHROUGH_VERTICAL_REEL="new_premium_walkthrough_vertical_reel",
  PREMIUM_WALKTHROUGH_HORIZONTAL_VIDEO="new_premium_walkthrough_horizontal_video",
  PREMIUM_WALKTHROUGH_VERTICAL_HORIZONTAL="new_premium_walkthrough_vertical_horizontal",
  EXPRESS_WALKTHROUGH_VERTICAL_REEL="new_express_hd_vertical_reel",
  EXPRESS_WALKTHROUGH_HORIZONTAL_VIDEO="new_express_hd_horizontal_video",
  EXPRESS_WALKTHROUGH_VERTICAL_HORIZONTAL="new_express_hd_vertical_horizontal",
  SOCIAL_MEDIA_REEL = "social_media_reel",
  EXTERIOR_ONLY="new_exterior_only",
  NEW_PRINTED_FLYER_DELIVER= "new_printed_flyers_deliver",
 PICKUP_AT_URL_OFFICE="new_printed_flyers_pick_up_at_rl_office"
}

export enum MarketingAddonCodes {
  STANDARD_FLYER = "new_standard_flyer",
  LUXURY_FLYER = "new_luxury_flyer",
  CLEAN_FLYER_DESIGN = "new_clean_flyer_design",
  MODERN_BANNER = "new_modern_banner",
  STANDARD_BANNER = "new_standard_banner",
  STANDARD_DOOR_HANGER = "new_standard_door_hanger",
  LUXURY_DOOR_HANGER = "new_luxury_door_hanger",
  CLEAN_DOOR_HANGER = "new_clean_door_hanger",
  STANDARD_LAYOUT = "new_standard_layout",
  CLASSIC_LAYOUT = "new_classic_layout",

}
