import { AnyAction } from "redux";

export interface StatusState {
  successStatus: boolean;
  successStatusOtherContacts: boolean;
  successStatusDeleteDiscountCode: boolean;
}

const INITIAL_STATE = {
  successStatus: false,
  successStatusOtherContacts: false,
  successStatusDeleteDiscountCode: false,


};

export const statusReducer = (
  state: StatusState = INITIAL_STATE,
  action: AnyAction
): StatusState => {
  const { type, payload } = action;
  switch (type) {
    case "REQUEST_SUCCESS": {
      return {
        ...state,
        successStatus: true,
      };
    }
    case "REQUEST_FAIL": {
      return {
        ...state,
        successStatus: false,
      };
    }

    case "TOGGLE_REQUEST_STATUS": {
      return {
        ...state,
        successStatus: !state.successStatus,
      };
    }
    case "REQUEST_SUCCESS_OTHER_CONTACTS": {
      return {
        ...state,
        successStatusOtherContacts: true,
      };
    }
    case "REQUEST_FAIL_OTHER_CONTACTS": {
      return {
        ...state,
        successStatusOtherContacts: false,
      };
    }
    case "TOGGLE_REQUEST_STATUS_OTHER_CONTACTS": {
      return {
        ...state,
        successStatusOtherContacts: !state.successStatusOtherContacts,
      };
    }
    case "REQUEST_SUCCESS_DELETE_DISCOUNT_CODE": {
      return {
        ...state,
        successStatusDeleteDiscountCode: !state.successStatusDeleteDiscountCode,
      };
    }

    default:
      return state;
  }
};

export default statusReducer;
