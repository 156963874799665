import React from "react";
import { Routes, Route, Navigate } from "react-router-dom";



const DashboardContainer = React.lazy(
  () => import("./client/containers/DashboardContainer")
);
const PackageContainer = React.lazy(
  () => import("./client/containers/PackageContainer")
);
const OrderSummaryContainer = React.lazy(
  () => import("./client/containers/OrderSummaryContainer")
);
const AdminDashboardContainer = React.lazy(
  () => import("./admin/containers/AdminDashboardContainer")
);

const LoggedOutPage = React.lazy(
  () => import("./shared/pages/LoggedOut/LoggedOutPage")
);

const ResetPasswordContainer = React.lazy(
  () => import("./client/containers/ResetPasswordContainer")
);
const RegisterPage = React.lazy(
  () => import("./shared/pages/Register/RegisterPage")
);

const InnerContent = React.lazy(
  () => import("./shared/containers/InnerContent/InnerContent")
);
const ProtectedRoutes = React.lazy(
  () => import("./shared/containers/ProtectedRoutes/ProtectedRoutes")
);
const PublicRoutes = React.lazy(
  () => import("./shared/containers/PublicRoutes/PublicRoutes")
);

const LoginPage = React.lazy(() => import("./shared/pages/Login/Login"));

const PermissionDenied = React.lazy(
  () => import("./shared/pages/PermissionDenied/PermissionDenied")
);
const NotFound = React.lazy(() => import("./shared/pages/NotFound/NotFound"));

const PackageWordPressContainer = React.lazy(
  () => import("./client/containers/PackageContainerWordpress")
);
const ProductContainer = React.lazy(() => import("./client/containers/ProductContainer"));

let replaceUrl = "/dashboard"
let user: any;
const _user = localStorage.getItem("user");
if (_user) {
  user = JSON.parse(_user);
}


// switch (user.user.admin_roles[0].role) {
//   case "admin": replaceUrl = "/admin/admin-dashboard"
//     break;
//   case "owner": replaceUrl = "/admin/admin-dashboard"
//     break;
//   default: replaceUrl = "/dashboard"
//     break
// }
if (user && user.user && user.user.admin_roles) {
  replaceUrl = "/admin/admin-dashboard"
} else {
  replaceUrl = "/dashboard"
}
const MainRoutes = () => (

  <Routes >
    {/** Protected Routes */}
    <Route path="/" element={<ProtectedRoutes />}>
      <Route path="/" element={<InnerContent />}>
        <Route path="/" element={<Navigate replace to={replaceUrl} />} />
        <Route path="/*" element={<DashboardContainer />} />
        <Route path="/pricing/*" element={<PackageContainer />} />
        <Route path="/order-summary/*" element={<OrderSummaryContainer />} />
        <Route
          path="admin"
          element={
            <ProtectedRoutes roleRequired={["admin", "super_admin", "owner"]} />
          }
        >
          <Route path="/admin/*" element={<AdminDashboardContainer />} />
        </Route>
      </Route>
    </Route>

    {/** Public Routes */}
    <Route path="/" element={<PublicRoutes />}>
      <Route path="/login" element={<LoginPage />} />
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/logged-out" element={<LoggedOutPage />} />
      <Route path="/reset-password/*" element={<ResetPasswordContainer />} />
    </Route>

    {/** Permission denied route */}
    <Route path="/denied" element={<PermissionDenied />} />
    <Route path="/not-found" element={<NotFound />} />
    <Route path="/package-price-wordpress" element={<PackageWordPressContainer />} />
    <Route path="/product-price-wordpress" element={<ProductContainer />} />
  </Routes>
);

export default MainRoutes;
